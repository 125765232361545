$rccs-unknown-background: linear-gradient(25deg, #a926fc, #4a0f8e);
$rccs-mastercard-background: linear-gradient(25deg, #a926fc, #4a0f8e);
$rccs-hipercard-background: linear-gradient(25deg, #a926fc, #4a0f8e);
$rccs-visa-background: linear-gradient(25deg, #a926fc, #4a0f8e);
$rccs-elo-background: linear-gradient(25deg, #a926fc, #4a0f8e);
$rccs-discover-background: linear-gradient(25deg, #a926fc, #4a0f8e);
$rccs-dinersclub-background: linear-gradient(25deg, #a926fc, #4a0f8e);
$rccs-background-transition: "all 0.001s ease-out";

@import "../../../node_modules/react-credit-cards/lib/styles.scss";
